var permissionsHelper = {

    routePermission(routeName) {
        var routeArray = routeName.substring('web.'.length)
        var datosPermisos = JSON.parse(localStorage.getItem("datosPermisos"))
        var index = datosPermisos.findIndex(
            element => element.recurso.substring('api.'.length) === routeArray
        )
        if (index == -1) {
            return false
        }
        return true
    }

}

export default permissionsHelper
