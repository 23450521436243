export default [
    {
        path: '/socioNegocio/:idSocio?',
        name: 'web.socioNegocio.listar',
        component: () => import('@/views/sociosNegocio/SociosNegocioListar.vue'),
        meta: {
            pageTitle: 'Socios de Negocio',
            breadcrumb: [
                {
                    text: 'Socios de Negocio',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
]

