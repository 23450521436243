export default [
    {
        path: '/facturas/imprimir',
        name: 'web.facturas.imprimir',
        component: () => import('@/views/facturas/FacturaPDF.vue'),
        meta: {
            pageTitle: 'Facturas',
            breadcrumb: [
                {
                    text: 'Imprimir Factura',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/facturas/listar',
        name: 'web.facturas.listar',
        component: () => import('@/views/facturas/FacturasListar.vue'),
        meta: {
            pageTitle: 'Facturas',
            breadcrumb: [
                {
                    text: 'Listado de Facturas',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/facturas/supervisor',
        name: 'web.facturas.supervisor',
        component: () => import('@/views/facturas/FacturasListar.vue'),
        meta: {
            pageTitle: 'Facturas',
            breadcrumb: [
                {
                    text: 'Listado de Facturas Supervisor',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/facturas/almacen',
        name: 'web.facturas.almacen',
        component: () => import('@/views/almacenes/AuthAlmacenesListar.vue'),
        meta: {
            pageTitle: 'Facturas',
            breadcrumb: [
                {
                    text: 'Listado de Almacenes',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
]

