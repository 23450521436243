import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import axios from 'axios';
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import VueSwal from 'vue-swal'
import Loading from 'vue-loading-overlay';
require('numeral');

import * as VueGoogleMaps from 'vue2-google-maps'
import VueExcelXlsx from "vue-excel-xlsx";

import 'vue-loading-overlay/dist/vue-loading.css';

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueSwal)
Vue.use(Loading);
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.MIX_GOOGLE_MAPS_API_KEY,
        libraries: 'places',
    }
});
Vue.use(VueExcelXlsx);

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.prototype.$http = axios;
const token = localStorage.getItem('token')
if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

var loader = "";
/*
Vue.config.errorHandler = (err, vm, info) => {
    console.log("Hubo un error de interfaz, verifique la consola.");
    console.log('Error')
    console.log(err)
    console.log('VM')
    console.log(err)
    console.log('Info')
    console.log(info)
};
*/

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
