export default [
    {
        path: "/clientes",
        name: "web.clientes.listar",
        component: () => import("@/views/clientes/ClientesListar.vue"),
        meta: {
            pageTitle: "Clientes",
            breadcrumb: [
                {
                    text: "Clientes",
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: "/clientes/crear",
        name: "web.clientes.crear",
        component: () => import("@/views/clientes/ClientesForm.vue"),
        meta: {
            pageTitle: "Clientes",
            breadcrumb: [
                {
                    text: "Clientes",
                    to: { name: "web.clientes.listar" },
                },
                {
                    text: "Nuevo Cliente",
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: "/clientes/editar",
        name: "web.clientes.modificar",
        component: () => import("@/views/clientes/ClientesForm.vue"),
        meta: {
            pageTitle: "Clientes",
            breadcrumb: [
                {
                    text: "Clientes",
                    to: { name: "web.clientes.listar" },
                },
                {
                    text: "Modificar Cliente",
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: "/consola-ventas/almacen",
        name: "web.consola.ventas.almacen",
        component: () => import("@/views/almacenes/AuthAlmacenesListar.vue"),
        meta: {
            pageTitle: "Ventas",
            breadcrumb: [
                {
                    text: "Almacenes",
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: "/consola-ventas/ver",
        name: "web.consola.ventas.ver",
        component: () => import("@/views/consolaVentas/ConsolaVentas.vue"),
        meta: {
            pageTitle: "Ventas",
            breadcrumb: [
                {
                    text: "Consola Ventas",
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: "/consola-ventas/socio-negocio",
        name: "web.consola.ventas.socioNegocio",
        component: () =>
            import("@/views/sociosNegocio/SociosNegocioListar.vue"),
        meta: {
            pageTitle: "Ventas",
            breadcrumb: [
                {
                    text: "Socios de Negocio",
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: "/eventosSignificativos/listar",
        name: "web.eventosSignificativos.listar",
        component: () =>
            import(
                "@/views/eventosSignificativos/EventosSignificativosListar.vue"
            ),
        meta: {
            pageTitle: "Ventas",
            breadcrumb: [
                {
                    text: "Eventos Significativos",
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: "/eventosSignificativos/almacen",
        name: "web.eventosSignificativos.almacen",
        component: () => import("@/views/almacenes/AuthAlmacenesListar.vue"),
        meta: {
            pageTitle: "Ventas",
            breadcrumb: [
                {
                    text: "Eventos Significativos Almacen",
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: "/consola-ventas/institucionales/almacen",
        name: "web.consola.ventas.institucionales.almacen",
        component: () => import("@/views/almacenes/AuthAlmacenesListar.vue"),
        meta: {
            pageTitle: "Ventas",
            breadcrumb: [
                {
                    text: "Almacenes Institucionales",
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: "/consola-ventas/institucionales/ver",
        name: "web.consola.ventas.institucionales.ver",
        component: () => import("@/views/consolaVentas/ConsolaVentas.vue"),
        meta: {
            pageTitle: "Ventas",
            breadcrumb: [
                {
                    text: "Consola Ventas Institucionales",
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: "/consola-ventas/institucionales/busquedaItem",
        name: "web.items.institucional.busqueda",
        component: () => import('@/views/items/pantallaBusquedaItem.vue'),
        meta: {
            pageTitle: 'Busqueda de Item',
            breadcrumb: [
                {
                    text: 'Ventas Institucionales',
                    to: { name: 'web.consola.ventas.institucionales.ver' }
                },
                {
                    text: 'Busqueda de Item',
                    active: true,
                },
            ],
            requiresAuth: true,
        },
    },
    {
        path: "/consola-ventas/institucionales/socio-negocio",
        name: "web.consola.ventas.institucionales.socioNegocio",
        component: () =>
            import("@/views/sociosNegocio/SociosNegocioListar.vue"),
        meta: {
            pageTitle: "Ventas",
            breadcrumb: [
                {
                    text: "Socios de Negocio",
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: "/consola-ventas/institucionales/ordenes-venta",
        name: "web.consola.ventas.institucionales.ordenesVenta",
        component: () =>
            import("@/views/ordenesVenta/OrdenesVentaListar.vue"),
        meta: {
            pageTitle: "Ventas",
            breadcrumb: [
                {
                    text: "Ordenes de Venta",
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
];
