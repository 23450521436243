export default {
    namespaced: true,
    state: {
        tituloForm: '' || 'Sucursales',
        idSucursal: '',
        dataFormParent: {}
    },
    getters: {},
    mutations: {},
    actions: {}
}
