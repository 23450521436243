import { httpLogged } from './http_service';

export function getAlmacenes(params) {
    let filters = params.filter;
    return httpLogged().get('/api/almacenes/' + filters + '')
}

export function patchAlmacen(payload) {
    return httpLogged().patch('/api/almacenes/'+payload.cod_almacen+'/'+payload.accion)
}

export function getAuthAlmacenes() {
    return httpLogged().get('/api/auth/almacenes?filter[inv_almacenes.almacen_ventas][eq]=1')
}
