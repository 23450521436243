export default [
    {
        path: '/sucursales',
        name: 'web.sucursales.listar',
        component: () => import('@/views/sucursales/SucursalesListar.vue'),
        meta: {
            pageTitle: 'Sucursales',
            breadcrumb: [
                {
                    text: 'Sucursales',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/sucursales/modificar',
        name: 'web.sucursales.modificar',
        component: () => import('@/views/sucursales/FormSucursales.vue'),
        meta: {
            pageTitle: 'Sucursales',
            breadcrumb: [
                {
                    text: 'Sucursales',
                    to: { name: 'web.sucursales.listar' }
                },
                {
                    text: 'Modificar Sucursal',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/sucursales/empleadosVentas',
        name: 'web.sucursales.empleadosVentas',
        component: () => import('@/views/sucursales/SucursalesEmpleadosVentas.vue'),
        meta: {
            pageTitle: 'Sucursales',
            breadcrumb: [
                {
                    text: 'Sucursales',
                    to: { name: 'web.sucursales.listar' }
                },
                {
                    text: 'Empleados Ventas Sucursal',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
]
