export default {
    namespaced: true,
    state: {
        tituloForm: '' || 'Usuarios',
        operationType: '' || 'U',
        idUsuario: '',
    },
    getters: {},
    mutations: {},
    actions: {}
}
