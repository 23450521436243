export default [
    {
        path: '/menus',
        name: 'web.menus.listar',
        component: () => import('@/views/menus/MenusListar.vue'),
        meta: {
            pageTitle: 'Menus',
            breadcrumb: [
                {
                    text: 'Menus',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/menus/crear',
        name: 'web.menus.crear',
        component: () => import('@/views/menus/FormMenu.vue'),
        meta: {
            pageTitle: 'Nuevo Menu',
            breadcrumb: [
                {
                    text: 'Menus',
                    to: { name: 'web.menus.listar' }
                },
                {
                    text: 'Nuevo Menu',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/menus/modificar',
        name: 'web.menus.modificar',
        component: () => import('@/views/menus/FormMenu.vue'),
        meta: {
            pageTitle: 'Modificar Menu',
            breadcrumb: [
                {
                    text: 'Menus',
                    to: { name: 'web.menus.listar' }
                },
                {
                    text: 'Modificar Menu',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
]
