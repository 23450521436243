import { httpLogged } from './http_service';

export function getSociosNegocio(params) {
    let filters = params.filters
    return httpLogged().get('/api/socioNegocio/'+filters)
}

export function syncSociosNegocio(params) {
    let payload = {
        tipo: params.optionSelected
    }
    return httpLogged().post('/api/socioNegocio/', payload)
}

export function syncSaldosSociosNegocio(params) {
    let payload = {
        tipo: params.tipo,
        CardCode: params.socioNegocio
    }
    return httpLogged().post('/api/socioNegocio/', payload)
}


