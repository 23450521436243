import axios from 'axios';
import store from '../store';

export function http() {
    return axios.create({
        baseURL: store.state.apiURL,
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export function httpLogged() {
    let token = String("Bearer " + store.state.auth.token)
    token = token.replace('"', '')
    return axios.create({
        baseURL: store.state.apiURL,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
        }
    });
}

export function httpLoggedFormData() {
    let token = String("Bearer " + store.state.auth.token)
    token = token.replace('"', '')
    return axios.create({
        baseURL: store.state.apiURL,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': token,
        }
    });
}
