export default [
    {
        path: '/reportes/ventas-facturas',
        name: 'web.reportes.ventas-facturas',
        component: () => import('@/views/reportes/ReporteVentasFacturas.vue'),
        meta: {
            pageTitle: 'Reportes',
            breadcrumb: [
                {
                    text: 'Reportes',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/reportes/ventas-fecha',
        name: 'web.reportes.ventas-fecha',
        component: () => import('@/views/reportes/ReporteVentasTotalesPorFecha.vue'),
        meta: {
            pageTitle: 'Reportes',
            breadcrumb: [
                {
                    text: 'Reportes',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/reportes/montos-facturas-dia',
        name: 'web.reportes.montos-facturas-dia',
        component: () => import('@/views/reportes/ReporteMontosFacturasPorDia.vue'),
        meta: {
            pageTitle: 'Reportes',
            breadcrumb: [
                {
                    text: 'Reportes',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/reportes/lista-picking',
        name: 'web.reportes.picking-factura',
        component: () => import('@/views/reportes/ReporteListaPicking.vue'),
        meta: {
            pageTitle: 'Reportes',
            breadcrumb: [
                {
                    text: 'Reportes',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
]
