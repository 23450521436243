export default [
    {
        path: "/masivos/listar",
        name: "web.masivos.listar",
        component: () =>
            import("@/views/facturasMasivas/FacturasMasivasListar.vue"),
        meta: {
            pageTitle: "Facturas Masivas",
            breadcrumb: [
                {
                    text: "Listado de Trabajos de Facturacion",
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: "/masivos/almacen",
        name: "web.masivos.almacen",
        component: () => import("@/views/almacenes/AuthAlmacenesListar.vue"),
        meta: {
            pageTitle: "Facturas Masivas",
            breadcrumb: [
                {
                    text: "Listado de Almacenes",
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: "/masivos/crear",
        name: "web.masivos.crear",
        component: () =>
            import("@/views/facturasMasivas/FacturasMasivasCrear.vue"),
        meta: {
            pageTitle: "Facturas Masivas",
            breadcrumb: [
                {
                    text: "Creacion de Trabajo",
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: "/masivosFacturas/listar",
        name: "web.masivosFacturas.listar",
        component: () =>
            import("@/views/facturasMasivas/FacturasMasivasFacturasListar.vue"),
        meta: {
            pageTitle: "Facturas Masivas",
            breadcrumb: [
                {
                    text: "Listado de Detalle de Facturas",
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: "/masivosFacturasItems/listar",
        name: "web.masivosFacturasItems.listar",
        component: () =>
            import("@/views/facturasMasivas/FacturasMasivasFacturasItemsListar.vue"),
        meta: {
            pageTitle: "Facturas Masivas",
            breadcrumb: [
                {
                    text: "Listado de Detalle de Items de Factura",
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
];
