export default {
    namespaced: true,
    state: {
        tituloForm: '' || 'Persona',
        operationType: '' || 'U',
        idPersona: '',
    },
    getters: {},
    mutations: {},
    actions: {}
}
