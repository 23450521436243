export default [
    {
        path: '/roles',
        name: 'web.roles.listar',
        component: () => import('@/views/roles/RolesListar.vue'),
        meta: {
            pageTitle: 'Roles',
            breadcrumb: [
                {
                    text: 'Roles',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/roles/crear',
        name: 'web.roles.crear',
        component: () => import('@/views/roles/RolesForm.vue'),
        meta: {
            pageTitle: 'Roles',
            breadcrumb: [
                {
                    text: 'Roles',
                    to: { name: 'web.roles.listar' }
                },
                {
                    text: 'Nuevo Rol',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/roles/modificar',
        name: 'web.roles.modificar',
        component: () => import('@/views/roles/RolesForm.vue'),
        meta: {
            pageTitle: 'Roles',
            breadcrumb: [
                {
                    text: 'Roles',
                    to: { name: 'web.roles.listar' }
                },
                {
                    text: 'Modificar Rol',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/roles/menus/listar',
        name: 'web.roles.menus.listar',
        component: () => import('@/views/roles/RolesMenusListar.vue'),
        meta: {
            pageTitle: 'Roles',
            breadcrumb: [
                {
                    text: 'Roles',
                    to: { name: 'web.roles.listar' }
                },
                {
                    text: 'Asignar Menus a Rol',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/roles/recursos/listar',
        name: 'web.roles.recursos.listar',
        component: () => import('@/views/roles/RolesRecursosListar.vue'),
        meta: {
            pageTitle: 'Roles',
            breadcrumb: [
                {
                    text: 'Roles',
                    to: { name: 'web.roles.listar' }
                },
                {
                    text: 'Asignar Recursos a Rol',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/roles/:cod_rol/almacenes/listar',
        name: 'web.roles.almacenes.listar',
        component: () => import('@/views/roles/RolesAlmacenesListar.vue'),
        meta: {
            pageTitle: 'Roles',
            breadcrumb: [
                {
                    text: 'Roles',
                    to: { name: 'web.roles.listar' }
                },
                {
                    text: 'Asignar Almacenes a Rol',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },


]
