export default [
    {
        path: '/personas',
        name: 'web.personas.listar',
        component: () => import('@/views/personas/PersonasListar.vue'),
        meta: {
            pageTitle: 'Personas',
            breadcrumb: [
                {
                    text: 'Personas',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/personas/crear',
        name: 'web.personas.crear',
        component: () => import('@/views/personas/FormPersona.vue'),
        meta: {
            pageTitle: 'Nueva Persona',
            breadcrumb: [
                {
                    text: 'Personas',
                    to: { name: 'web.personas.listar' }
                },
                {
                    text: 'Nueva Persona',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/personas/modificar',
        name: 'web.personas.modificar',
        component: () => import('@/views/personas/FormPersona.vue'),
        meta: {
            pageTitle: 'Modificar Persona',
            breadcrumb: [
                {
                    text: 'Personas',
                    to: { name: 'web.personas.listar' }
                },
                {
                    text: 'Modificar Persona',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/clientes/personas/buscar',
        name: 'web.personas.buscar',
        component: () => import('@/views/personas/PantallaBusquedaPersona.vue'),
        meta: {
            pageTitle: 'Clientes',
            breadcrumb: [
                {
                    text: 'Clientes',
                    to: { name: 'web.clientes.listar' }
                },
                {
                    text: 'Buscar Persona',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
]
