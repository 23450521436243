import { httpLogged } from "./http_service";

export function getSucursales(params) {
    let filters = params.filters;
    return httpLogged().get("/api/sucursales" + filters + "");
}

export function postSucursales(opcion) {
    let payload = {
        tipo: opcion,
    };
    return httpLogged().post("/api/sucursales/", payload);
}

export function putSucursales(id, payload) {
    let id_sucursal = id;
    return httpLogged().put("/api/sucursales/" + id_sucursal, payload);
}

export function patchSucursal(params) {
    return httpLogged().patch(
        "/api/sucursales/" + params.id_sucursal,
        params.payload
    );
}
