import * as sucursalesService from "@/services/sucursales_service";
import * as almacenesService from "@/services/almacenes_service";
import * as sociosNegocioService from "@/services/sociosNegocio_service";

export default {
    namespaced: true,
    state: {
        almacenVentas: localStorage.getItem("almacenVentas") || "",
        sucursalVentas: localStorage.getItem("sucursalVentas") || "",
        socioNegocio: localStorage.getItem("socioNegocio") || "",
        listaPrecios: localStorage.getItem("listaPrecios") || "",
        codigoItemManual: "",
        ordenesVentas: [],
        carritoVentas: {
            idPersona: "",
            idCliente: "",
            num_factura: "",
            nit: "",
            razonSocial: "",
            tipoDocumento: "",
            email: "",
            celular: "",
            num_autorizacion: "",
            vendedorCabecera: "",
            itemsVenta: [],
            fechaLimiteEmision: "",
            fechaFactura: "",
            facturaManual: "N",
            efectivoBOB: 0,
            efectivoUSD: 0,
            tarjetaBOB: 0,
            tipoTarjeta: "3",
            numeroTarjeta: "",
            transferenciaBOB: 0,
            referenciaTransferencia: "",
            fechaTransferencia: "",
            desc_tarjeta: "",
            monto_giftcard_BOB: 0,
            serie_giftcard: "",
            tipo_origen_factura: "",
            tipo_pago: "",
            tipo_anticipo: "",
            es_reserva: "",
            pago_reserva: 0,
            descuento_porc: 0,
            descuento_monto_BOB: 0,
            billete_SUS: 0,
            monto_total_pago_BOB: 0,
            monto_cheque_BOB: 0,
        },
    },
    getters: {
        almacenVentas: (state) => {
            return state.almacenVentas;
        },
        sucursalVentas: (state) => {
            return state.sucursalVentas;
        },
        socioNegocio: (state) => {
            return state.socioNegocio;
        },
        listaPrecios: (state) => {
            return state.listaPrecios;
        },
        carritoVentas: (state) => {
            return state.carritoVentas;
        },
        codigoItemManual: (state) => {
            return state.codigoItemManual;
        },
        ordenesVentas: (state) => {
            return state.ordenesVentas;
        },
    },
    mutations: {
        SET_ALMACENVENTAS: (state, newValue) => {
            localStorage.setItem("almacenVentas", newValue);
            state.almacenVentas = newValue;
        },
        SET_SUCURSALVENTAS: (state, newValue) => {
            localStorage.setItem("sucursalVentas", newValue);
            state.sucursalVentas = newValue;
        },
        SET_SOCIONEGOCIO: (state, newValue) => {
            localStorage.setItem("socioNegocio", newValue);
            state.socioNegocio = newValue;
        },
        SET_LISTAPRECIOS: (state, newValue) => {
            localStorage.setItem("listaPrecios", newValue);
            state.listaPrecios = newValue;
        },
        SET_CARRITOVENTAS: (state, newValue) => {
            state.carritoVentas = newValue;
        },
        SET_ORDENESVENTAS: (state, newValue) => {
            state.ordenesVentas = newValue;
        },
        SET_CODIGOITEMMANUAL: (state, newValue) => {
            state.codigoItemManual = newValue;
        },
        CLEAR_CARRITOVENTAS(state) {
            let carritoVentasObject = {
                idPersona: "",
                idCliente: "",
                num_factura: "",
                nit: "",
                razonSocial: "",
                tipoDocumento: "",
                email: "",
                celular: "",
                num_autorizacion: "",
                vendedorCabecera: "",
                itemsVenta: [],
                fechaLimiteEmision: "",
                fechaFactura: "",
                facturaManual: "N",
                efectivoBOB: 0,
                efectivoUSD: 0,
                tarjetaBOB: 0,
                tipoTarjeta: "3",
                numeroTarjeta: "",
                transferenciaBOB: 0,
                referenciaTransferencia: "",
                fechaTransferencia: "",
                desc_tarjeta: 0,
                monto_giftcard_BOB: 0,
                serie_giftcard: "",
                tipo_origen_factura: "",
                tipo_pago: "",
                tipo_anticipo: "",
                es_reserva: "",
                pago_reserva: 0,
                descuento_porc: 0,
                descuento_monto_BOB: 0,
                billete_SUS: 0,
                monto_total_pago_BOB: 0,
            };
            state.carritoVentas = carritoVentasObject;
        },
        CLEAR_ALMACENVENTAS(state) {
            state.almacenVentas = "";
            state.sucursalVentas = "";
            state.socioNegocio = "";
            state.listaPrecios = "";
            localStorage.removeItem("almacenVentas");
            localStorage.removeItem("sucursalVentas");
            localStorage.removeItem("socioNegocio");
            localStorage.removeItem("listaPrecios");
        },
        CLEAR_CODIGOITEMMANUAL(state) {
            state.codigoItemManual = "";
        },
    },
    actions: {
        async setAlmacenVentas({ commit, state }, almacen) {
            commit("SET_ALMACENVENTAS", almacen.cod_almacen);
            let almacenData = {};
            let sucursalData = {};
            let socioNegocioData = {};
            try {
                let params = {
                    filter: "?filter[cod_almacen][eq]=" + almacen.cod_almacen,
                };
                const responceAlmacen = await almacenesService.getAlmacenes(
                    params
                );
                almacenData = responceAlmacen.data.data[0];

                commit("SET_SUCURSALVENTAS", almacenData.id_sucursal);
            } catch (error) {
                console.log(error);
            }
            try {
                let params = {
                    filters:
                        "?filter[id_sucursal][eq]=" + almacenData.id_sucursal,
                };
                const responseSucursal = await sucursalesService.getSucursales(
                    params
                );
                sucursalData = responseSucursal.data.data[0];
                commit("SET_SOCIONEGOCIO", sucursalData.socio_negocio);
            } catch (error) {
                console.log(error);
            }
            try {
                let params = {
                    filters:
                        "?filter[CardCode][eq]=" + sucursalData.socio_negocio,
                };
                const responseSocioNegocio =
                    await sociosNegocioService.getSociosNegocio(params);
                socioNegocioData = responseSocioNegocio.data.data[0];
                commit("SET_LISTAPRECIOS", socioNegocioData.PriceList);
            } catch (error) {
                console.log(error);
            }
        },
        setSucursalVentas({ commit, state }, almacenData) {
            commit("SET_SUCURSALVENTAS", almacenData.id_sucursal);
        },
        setSocioNegocioVentas({ commit, state }, sucursalData) {
            commit("SET_SOCIONEGOCIO", sucursalData.socio_negocio);
        },
        setListaPrecioVentas({ commit, state }, socioNegocioData) {
            commit("SET_LISTAPRECIOS", socioNegocioData.PriceList);
        },
        setSocioNegocio({ commit, state }, socioNegocio) {
            commit("SET_SOCIONEGOCIO", socioNegocio.CardCode);
            commit("SET_LISTAPRECIOS", socioNegocio.PriceList);
        },
        setCodigoItemManual({ commit, state }, codigoItemManual) {
            commit("SET_CODIGOITEMMANUAL", codigoItemManual);
        },
        limpiarCodigoItemManual({ commit, state }) {
            commit("CLEAR_CODIGOITEMMANUAL");
        },
        setCarritoVentas({ commit, state }, carritoVentas) {
            commit("SET_CARRITOVENTAS", carritoVentas);
        },
        limpiarAlmacenVentas({ commit, state }) {
            commit("CLEAR_ALMACENVENTAS");
        },
        limpiarCarritoVentas({ commit, state }) {
            commit("CLEAR_CARRITOVENTAS");
            commit("CLEAR_CODIGOITEMMANUAL");
        },
        setOrdenesVentas({ commit, state }, ordenesVentas) {
            commit("SET_ORDENESVENTAS", ordenesVentas);
        },
    },
};
