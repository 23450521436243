export default {
    namespaced: true,
    state: {
        dataFormObject: {},
    },
    getters: {
        dataFormObject: (state) => {
            return state.dataFormObject
        },
    },
    mutations: {
        SET_DATAFORMOBJECT: (state, newValue) => {
            state.dataFormObject = newValue
        },
    },
    actions: {
        setDataFormObject: ({ commit, state }, newValue) => {
            commit('SET_DATAFORMOBJECT', newValue)
            return state.dataFormObject
        },
    }
}
