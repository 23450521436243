export default [
    {
        path: '/empleados/:idEmpleado?',
        name: 'web.empleados.listar',
        component: () => import('@/views/empleados/EmpleadosListar.vue'),
        meta: {
            pageTitle: 'Empleados',
            breadcrumb: [
                {
                    text: 'Empleados',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
]

