import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import permissionHelper from '../helpers/permissionsHelper'

import personasRoutes from "@/router/personasRoutes"
import dominiosRoutes from "@/router/dominiosRoutes"
import menusRoutes from "@/router/menusRoutes"
import rolesRoutes from "@/router/rolesRoutes"
import usuariosRoutes from "@/router/usuariosRoutes"
import sucursalesRoutes from "@/router/sucursalesRoutes"
import dosificacionesRoutes from "@/router/dosificacionesRoutes"
import almacenesRoutes from "@/router/almacenesRoutes"
import inventariosRoutes from "@/router/inventariosRoutes"
import unidadesMedidaRoutes from "@/router/unidadesMedidaRoutes"
import rrhhRoutes from "@/router/rrhhRoutes"
import ventasRoutes from "@/router/ventasRoutes"
import vistasConfiguracionesRoutes from "@/router/vistasConfiguracionesRoutes"
import facturasRoutes from "@/router/facturasRoutes"
import reportesRoutes from "@/router/reportesRoutes"
import incidentesRoutes from "@/router/incidentesRoutes"
import facturacionMasivaRoutes from "@/router/facturacionMasivaRoutes"

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'web.home',
            component: () => import('@/views/Home.vue'),
            meta: {
                pageTitle: 'Inicio',
                breadcrumb: [
                    {
                        text: 'Inicio',
                        active: true,
                    },
                ],
                requiresAuth: true,
            },
        },
        ...personasRoutes,
        ...dominiosRoutes,
        ...menusRoutes,
        ...rolesRoutes,
        ...usuariosRoutes,
        ...sucursalesRoutes,
        ...dosificacionesRoutes,
        ...almacenesRoutes,
        ...inventariosRoutes,
        ...unidadesMedidaRoutes,
        ...rrhhRoutes,
        ...vistasConfiguracionesRoutes,
        ...ventasRoutes,
        ...facturasRoutes,
        ...reportesRoutes,
        ...incidentesRoutes,
        ...facturacionMasivaRoutes,
        {
            path: '/empresas',
            name: 'web.empresas.listar',
            component: () => import('@/views/BlankPage.vue'),
            meta: {
                pageTitle: 'Empresas',
                breadcrumb: [
                    {
                        text: 'Empresas',
                        active: true,
                    },
                ],
                requiresAuth: true,
                permissionRequired: true,
            },
        },
        {
            path: '/unauthorized',
            name: 'unauthorized',
            component: () => import('@/views/error/Error401.vue'),
            meta: {
                pageTitle: 'Sin Autorizacion',
                requiresAuth: true,
            },
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Login.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters['auth/isLoggedIn']) {
            if (to.matched.some(record => record.meta.permissionRequired)) {
                var access = permissionHelper.routePermission(to.name)
                if (!access) {
                    next('/unauthorized').catch(() => { })
                }
            }
            next()
            return
        }
        next('/login').catch(() => { })
    } else {
        next()
    }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
