export default [
    {
        path: '/unidadesMedida/:idUnidadMedida?',
        name: 'web.unidadesMedida.listar',
        component: () => import('@/views/unidadesMedida/UnidadesMedidaListar.vue'),
        meta: {
            pageTitle: 'Unidades de Medida',
            breadcrumb: [
                {
                    text: 'Unidades de Medida',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
]

