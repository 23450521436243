export default [
    {
        path: '/usuarios',
        name: 'web.usuarios.listar',
        component: () => import('@/views/usuarios/UsuariosListar.vue'),
        meta: {
            pageTitle: 'Usuarios',
            breadcrumb: [
                {
                    text: 'Usuarios',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/usuarios/crear',
        name: 'web.usuarios.crear',
        component: () => import('@/views/usuarios/UsuariosForm.vue'),
        meta: {
            pageTitle: 'Usuarios',
            breadcrumb: [
                {
                    text: 'Usuarios',
                    to: { name: 'web.usuarios.listar' }
                },
                {
                    text: 'Nuevo Usuario',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/usuarios/modificar',
        name: 'web.usuarios.modificar',
        component: () => import('@/views/usuarios/UsuariosForm.vue'),
        meta: {
            pageTitle: 'Usuarios',
            breadcrumb: [
                {
                    text: 'Usuarios',
                    to: { name: 'web.usuarios.listar' }
                },
                {
                    text: 'Modificar Usuario',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/usuarios/roles/listar',
        name: 'web.usuarios.roles.listar',
        component: () => import('@/views/usuarios/UsuariosRolesListar.vue'),
        meta: {
            pageTitle: 'Usuarios',
            breadcrumb: [
                {
                    text: 'Usuarios',
                    to: { name: 'web.usuarios.listar' }
                },
                {
                    text: 'Asignar Roles a Usuario',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/passwordChange',
        name: 'api.auth.passwordChangeApi',
        component: () => import('@/views/usuarios/CambioPasswordForm.vue'),
        meta: {
            pageTitle: 'Perfil',
            breadcrumb: [
                {
                    text: 'Perfil de Usuario',
                    active: true,
                },
            ],
            requiresAuth: true,
        },
    },
]
