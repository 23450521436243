export default [
    {
        path: '/dosificaciones',
        name: 'web.dosificaciones.listar',
        component: () => import('@/views/dosificaciones/DosificacionesListar.vue'),
        meta: {
            pageTitle: 'Dosificaciones',
            breadcrumb: [
                {
                    text: 'Dosificaciones',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/dosificaciones/crear',
        name: 'web.dosificaciones.crear',
        component: () => import('@/views/dosificaciones/FormDosificacion.vue'),
        meta: {
            pageTitle: 'Nueva Dosificacion',
            breadcrumb: [
                {
                    text: 'Dosificaciones',
                    to: { name: 'web.dosificaciones.listar' }
                },
                {
                    text: 'Nueva Dosificacion',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/dosificaciones/modificar',
        name: 'web.dosificaciones.modificar',
        component: () => import('@/views/dosificaciones/FormDosificacion.vue'),
        meta: {
            pageTitle: 'Modificar Dosificacion',
            breadcrumb: [
                {
                    text: 'Dosificaciones',
                    to: { name: 'web.dosificaciones.listar' }
                },
                {
                    text: 'Modificar Dosificacion',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
]
