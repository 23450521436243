export default {
    namespaced: true,
    state: {
        transferenciaSolicitudObjeto: {},
    },
    getters: {
        transferenciaSolicitudObjeto: (state) => {
            return state.transferenciaSolicitudObjeto
        },
    },
    mutations: {
        SET_TRANSFERENCIASOLICITUDOBJETO: (state, newValue) => {
            state.transferenciaSolicitudObjeto = newValue
        },
    },
    actions: {
        setTransferenciaSolicitudObjeto({ commit, state }, transferenciaSolicitudObjeto) {
            commit('SET_TRANSFERENCIASOLICITUDOBJETO', transferenciaSolicitudObjeto)
        },
    }
}
