import axios from 'axios';

export default {
    namespaced: true,
    state: {
        apiURL: '',
        serverPath: '',
        token: localStorage.getItem('token') || '',
        status: '',
        user: localStorage.getItem('user') || {},
        datosUsuario: localStorage.getItem('datosUsuario') || {},
        datosPersona: localStorage.getItem('datosPersona') || {},
        role: localStorage.getItem('role') || {},
        datosPermisos: localStorage.getItem('datosPermisos') || {},
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, payload) {
            state.status = 'success'
            state.token = payload.token
            localStorage.setItem('token', JSON.stringify(payload.token))
            localStorage.setItem('user', JSON.stringify(payload.user))
            localStorage.setItem('datosPersona', JSON.stringify(payload.datosPersona))
            localStorage.setItem('datosUsuario', JSON.stringify(payload.datosUsuario))
            localStorage.setItem('datosPermisos', JSON.stringify(payload.datosPermisos))
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.token = ''
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            localStorage.removeItem('datosPersona')
            localStorage.removeItem('datosUsuario')
            localStorage.removeItem('datosPermisos')
        },
        setURLs(state, payload) {
            state.apiURL = payload.apiURL
            state.serverPath = payload.serverPath
        }
    },
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
    },
    actions: {
        login({ commit, state }, user) {
            let url = state.apiURL;
            var username = user.login
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({ url: '/api/login', data: user, method: 'POST' })
                    .then(resp => {
                        const resultado = resp.data.resultado
                        if (resultado == true) {
                            const token = resp.data.data.token
                            const datosPersona = resp.data.data.persona
                            const datosUsuario = resp.data.data.usuario
                            const datosPermisos = resp.data.data.permisos.body.data
                            const user = username
                            localStorage.setItem('token', token)
                            state.datosPersona = datosPersona
                            state.datosUsuario = datosUsuario
                            axios.defaults.headers.common['Authorization'] = token
                            commit('auth_success', { token: token, user: username, datosPersona: datosPersona, datosUsuario: datosUsuario, datosPermisos: datosPermisos })
                            resolve(resp)
                        } else {
                            commit('auth_error')
                            localStorage.removeItem('token')
                            localStorage.removeItem('user')
                            localStorage.removeItem('role')
                            //reject(err)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        commit('auth_error')
                        localStorage.removeItem('token')
                        localStorage.removeItem('user')
                        localStorage.removeItem('role')
                        reject(err)
                    })
            })
        },
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                commit('logout')
                localStorage.removeItem('token')
                delete axios.defaults.headers.common['Authorization']
                resolve()
            })
        }
    }
};
