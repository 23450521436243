export default [
    {
        path: '/dominios',
        name: 'web.dominios.listar',
        component: () => import('@/views/dominios/DominiosListar.vue'),
        meta: {
            pageTitle: 'Dominios',
            breadcrumb: [
                {
                    text: 'Dominios',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/dominios/crear',
        name: 'web.dominios.codigo.crear',
        component: () => import('@/views/dominios/FormDominio.vue'),
        meta: {
            pageTitle: 'Nuevo Dominio',
            breadcrumb: [
                {
                    text: 'dominios',
                    to: { name: 'web.dominios.listar' }
                },
                {
                    text: 'Nuevo Dominio',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/dominios/modificar',
        name: 'web.dominios.modificar',
        component: () => import('@/views/dominios/FormDominio.vue'),
        meta: {
            pageTitle: 'Modificar Dominio',
            breadcrumb: [
                {
                    text: 'Dominio',
                    to: { name: 'web.dominios.listar' }
                },
                {
                    text: 'Modificar Dominio',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
]
