import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app/app_store'
import appConfig from './app-config/app_config_store'
import auth from './auth/auth_store'
import persona from './persona/persona_store'
import clientes from './clientes/clientes_store'
import dominios from './dominios/dominios_store'
import menus from './menus/menus_store'
import roles from './roles/roles_store'
import usuarios from './usuarios/usuarios_store'
import sucursales from './sucursales/sucursales_store'
import dosificaciones from './dosificaciones/dosificaciones_store'
import itemsGrupos from './items-grupos/itemsGrupos_store'
import verticalMenu from './vertical-menu/vertical_menu_store'
import consolaVentas from './consola_ventas/consola_ventas_store'
import reportes from './reportes/reportes_store'



Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    auth,
    persona,
    dominios,
    menus,
    roles,
    usuarios,
    sucursales,
    dosificaciones,
    verticalMenu,
    itemsGrupos,
    clientes,
    consolaVentas,
    reportes,
  },
  strict: process.env.DEV,
})
