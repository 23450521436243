export default {
    namespaced: true,
    state: {
        tituloForm: '' || 'Menus',
        operationType: '' || 'U',
        idMenu: '',
    },
    getters: {},
    mutations: {},
    actions: {}
}
