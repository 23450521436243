export default [
    {
        path: '/almacenes/:idSucursal',
        name: 'web.almacenes.listar',
        component: () => import('@/views/almacenes/AlmacenesListar.vue'),
        meta: {
            pageTitle: 'Almacenes',
            breadcrumb: [
                {
                    text: 'Sucursales',
                    to: { name: 'web.sucursales.listar' }
                },
                {
                    text: 'Almacenes de Sucursal',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/auth/almacenes/',
        name: 'web.auth.almacenes.listar',
        component: () => import('@/views/almacenes/AuthAlmacenesListar.vue'),
        meta: {
            pageTitle: 'Almacenes de Ventas',
            breadcrumb: [
                {
                    text: 'Almacenes de Ventas',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/itemsAlmacenes/',
        name: 'web.itemsAlmacenes.listar',
        component: () => import('@/views/almacenes/ItemsAlmacenesListar.vue'),
        meta: {
            pageTitle: 'Almacenes de Ventas',
            breadcrumb: [
                {
                    text: 'Almacenes',
                    to: { name: 'web.auth.almacenes.listar' }
                },
                {
                    text: 'Items Almacenes',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/itemsAlmacenesLotes/',
        name: 'web.itemsAlmacenesLotes.listar',
        component: () => import('@/views/almacenes/ItemsAlmacenesLotesListar.vue'),
        meta: {
            pageTitle: 'Almacenes de Ventas',
            breadcrumb: [
                {
                    text: 'Almacenes',
                    to: { name: 'web.auth.almacenes.listar' }
                },
                {
                    text: 'Items Almacenes Lotes',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/itemsAlmacenesSeries/',
        name: 'web.itemsAlmacenesSeries.listar',
        component: () => import('@/views/almacenes/ItemsAlmacenesSeriesListar.vue'),
        meta: {
            pageTitle: 'Almacenes de Ventas',
            breadcrumb: [
                {
                    text: 'Almacenes',
                    to: { name: 'web.auth.almacenes.listar' }
                },
                {
                    text: 'Items Almacenes Series',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/itemsAlmacenes/existencias/',
        name: 'web.itemsAlmacenes.existencias',
        component: () => import('@/views/almacenes/ItemsAlmacenesExistencias.vue'),
        meta: {
            pageTitle: 'Almacenes de Ventas Existencias',
            breadcrumb: [
                {
                    text: 'Almacenes',
                    to: { name: 'web.auth.almacenes.listar' }
                },
                {
                    text: 'Items Almacenes Existencias',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
]

